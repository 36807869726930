import React from "react";

import Page from "lib/@cms/components/layout/Page";
import PageHeader from "lib/@cms/components/cms/PageHeader";
import Text from "lib/@cms/components/shared/Text";
import ContentBox from "lib/@cms/components/shared/ContentBox";
import Button from "lib/@cms/components/primitive/Button";
import Divider from "lib/@cms/components/primitive/Divider";

function NewslettersPage() {
  return (
    <Page pathname="newsletters">
      {data => {
        return (
          <React.Fragment>
            <PageHeader
              variant={PageHeader.variant.GRADIENTS}
              color={PageHeader.color.PRIMARY}
              data={data.PageHeaderGradients}
            />
            <Divider className="tw-my-2" />

            <div className="tw-container">
              <Text variant={Text.variant.BODY}>{data.MetaData.description}</Text>

              <div className="tw-flex tw-flex-wrap-reverse tw-justify-">
                {data.Newsletters?.map((element, index1) => (
                  <div
                    key={`Newsletters-element-${index1}`}
                    className="tw-w-full sm:tw-w-1/2 lg:tw-w-1/3 tw-flex"
                  >
                    <ContentBox title={element.title}>
                      <ul className="tw-list-disc tw-ml-6">
                        {element.pdf &&
                          element.pdf?.map((item, index2) => {
                            return (
                              <div key={`pdf-item-${index2}`} className="tw-py-2">
                                <Button
                                  is="a"
                                  href={item.pdf ? item.pdf.url : "/404"}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {item.label}
                                </Button>
                              </div>
                            );
                          })}
                      </ul>
                    </ContentBox>
                    <Divider className="tw-my-7 sm:tw-my-10" />
                  </div>
                ))}
              </div>
            </div>
            <Divider className="tw-my-16 sm:tw-my-24" />
          </React.Fragment>
        );
      }}
    </Page>
  );
}

export default NewslettersPage;
